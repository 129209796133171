import axios from 'axios'
import host from '@/config/host'
import router from '@/router'

const service = axios.create({
    baseURL: host,
    timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        config['headers']['post']['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        config.headers.common['X-Requested-With'] = 'XMLHttpRequest'
        config.headers.Authorization = window.localStorage.access_token
        // config['url'] = config['url'] + '?token=' + window.localStorage.access_token
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    res => {
        // Toast.clear()
        const response = res.data;
        // switch (response.code) {
        //     case 1001:
        //         break;
        //     case 200:
        //         break;
        //     case 400:
        //         //console.log('未授权，请登录')
        //         //window.location.href = '/#/login'
        //         router.push({ path: "/login" })
        //         break
        //     case 401:
        //         //console.log('未授权，请登录')
        //         // window.location.href = '/#/login'
        //         router.push({ path: "/login" })
        //         break
        //     case 404:
        //         //console.log('接口请求异常: ' + err.response.config.url + ', 请重试')
        //         break
        //     case 422:
        //         //console.log('未授权，请登录')
        //         //window.location.href = '/#/'
        //         break
        //     default:
        //     //console.log('Oops, 出错啦')
        // }
        if (response.msg) {

            if (response.code === 200) {
                // Toast.success('网络请求超时！')
                //console.log(response.msg)
            } else {
                // Toast.fail('网络请求超时！')
                // console.log(response.msg)
            }
        }
        return response
    },
    err => {
        // Toast.clear()
        requestList.length = 0;
        if (err && err.response) {
            // switch (err.response.code) {
            //     case 400:
            //         //console.log('未授权，请登录')
            //         // window.location.href = '/#/login'
            //         router.push({ path: "/login" })
            //         break
            //     case 401:
            //         //console.log('未授权，请登录')
            //         // window.location.href = '/#/login'
            //         router.push({ path: "/login" })
            //         break
            //     case 404:
            //         //console.log('接口请求异常: ' + err.response.config.url + ', 请重试')
            //         break
            //     default:
            //     //console.log('Oops, 出错啦')
            // }
        }
        return Promise.reject(err)

    }
)


export default service